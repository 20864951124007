<script setup lang="ts">
import { onMounted } from 'vue'

defineLayout({
  label: 'No Chrome Layout',
})

const links: Array<object> = [
  { rel: 'icon', href: '/imgs/playa/favicons/icon-192.png', type: 'image/png' },
  { rel: 'icon', href: '/imgs/playa/favicons/icon.svg', type: 'image/svg+xml' },
  { rel: 'apple-touch-icon', href: '/imgs/playa/favicons/apple-touch-icon.png', type: 'image/png' },
  { rel: 'manifest', href: '/imgs/playa/favicons/manifest.json', type: 'application/json' },
]

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const { getCommonLayoutData, playaLayoutMounted, setupHeader } = usePlayaLayout()

if (!isVoixTemplatizer)
  setupHeader(null, links)

const { currentResort } = await getCommonLayoutData(
  isVoixTemplatizer,
  [
    'resorts',
    'brands',
    'footerCompanyMenu',
    'footerResourcesMenu',
    'footerTermsMenu',
  ],
)

onMounted(() => {
  if (!isVoixTemplatizer)
    return
  playaLayoutMounted(currentResort)
})
</script>

<template>
  <div>
    <div
      class="text-grey-darker bg-abs-white playaresorts-nochrome-theme"
    >
      <div id="app">
        <div id="portal-destination" transition="fade-transition" />
        <div id="portal-mobile-menu" role="navigation" />
        <div id="playa-content">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .playaresorts-nochrome-theme {
    @import '~/assets/css/playaresorts.scss';
  }
</style>
